import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderSection from '../components/Careers/CareerDetail/HeaderSection/HeaderSection'

const Career = data => {
    
    // Collect data
    const detail = data.data.careerData

    // SEO
    let seo = {
      title: data.data.careerData.seo.title,
      description: data.data.careerData.seo.description,
      lang: data.data.careerData.language,
      image: data.data.careerData.seo.social.facebook.image && data.data.careerData.seo.social.facebook.image.url,
      imageTwitter: data.data.careerData.seo.social.twitter.image && data.data.careerData.seo.social.twitter.image.url,
      robots: data.data.careerData.seo.advanced.robots && data.data.careerData.seo.advanced.robots
    }
    
    // 1. Language switcher - Create slugs object for language switcher
    const slugs = {
      slugNl: '',
      slugEn: '',
    }
    
    // 2. Language switcher - Build slugs depending on language
    if (data.data.careerData.language == "en"){
      slugs.slugNl = 'jobs/'+data.data.careerTranslation.slug
      slugs.slugEn = 'careers/'+data.data.careerData.slug
    } else {
      slugs.slugNl = 'jobs/'+data.data.careerData.slug
      slugs.slugEn = 'careers/'+data.data.careerTranslation.slug
    }

    return(
      <Layout data={data.data.site} location={data.location} slugs={slugs}>
        <SEO {...seo} />
        <HeaderSection data={detail} />
      </Layout>
    )
}
  
export default Career

export const query = graphql`
query ($id: ID!, $siteId:Int, $siteTransId:Int) {
  site {
    siteMetadata {
      languages {
        defaultLangKey
        langs
      }
    }
  }
  careerData:craftCareerCarreerEntry(remoteId: {eq: $id}, siteId: {eq: $siteId}) {
      title
      id
      slug
      careerIntro
      careerMainImage {
        url
      }
      careerTextBlock1
      careerTextBlock2
      careerTextBlock3
      language
      seo {
        title
        description
        social {
          facebook {
            image {
              url
            }
          }
          twitter {
            image {
              url
            }
          }
        }
        advanced{
          robots
        }
      }
  }
  careerTranslation:craftCareerCarreerEntry(remoteId: {eq: $id}, siteId: {eq: $siteTransId}) {
    slug
  }
}
`
