import React from 'react'
import styled from 'styled-components'
import { FormattedMessage, useIntl } from 'react-intl';

import { colors, media } from '../../../../lib/style-utils'
import Form from './Form'
import Section from '../../../../styles/Section'

/* SectionExtended */
const SectionExtended = styled(Section)`
    padding-top: 0; 
    padding-bottom: 0;
    max-width: 100%;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    ${media.phablet`
        padding-left: 2rem;
        padding-right: 2rem;
    `}
    ${media.desktop`
        width: 88%;
        max-width: 88%;
    `}
`
const Container = styled.div`
    margin-top: 2rem;
    ${media.desktop`
      margin-top: 4rem;
    `}
`
const BackgroundGradient = styled.div`
    background: ${colors.gradient};
    padding: 3rem 0;
`
const FormWrapper = styled.div`
    width: 50rem;
    max-width: 100%;
    margin: 0 auto;
    form{
      padding: 1rem 1.5rem;
    }
    ${media.midDesktop`
      padding: 1rem 3rem;
    `}
`
const FormTitle = styled.h1`
margin-bottom: 3rem;
  font-size: 2.5rem;
  font-weight: 800;
  ${media.tablet`
    font-size: 4rem;
  `}
`

const ContactForm = ({jobtype}) => {
    const intl = useIntl()
 
    return(
      <Container>
          <SectionExtended>
            <FormTitle>
              <FormattedMessage id="applyNow"/>
            </FormTitle>
          </SectionExtended>
          <BackgroundGradient>
            <SectionExtended>
              <FormWrapper>
                <Form jobtype={jobtype} intl={useIntl()}/>
              </FormWrapper>
            </SectionExtended>
          </BackgroundGradient>
      </Container>
    )
}

export default ContactForm