import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl';
import { media,colors } from '../../../../lib/style-utils'
import AniLink from "gatsby-plugin-transition-link/AniLink"
import parse from 'html-react-parser'

import Section from '../../../../styles/Section'
import SocialIcons from '../../../General/Social/Social'
import Arrow from '../../../General/Icons/Arrow'
import ContactForm from '../CareerForm/CareerForm'
import GlobalTextStyles from '../../../General/Text/GlobalTextStyles'

import RevealOpacity from '../../../Animation/RevealOpacity'

/* SectionExtended */
const SectionExtended = styled(Section)`
    padding-top: 0; 
    padding-bottom: 0;
    max-width: 100%;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    ${media.phablet`
        padding-left: 2rem;
        padding-right: 2rem;
    `}
    ${media.desktop`
        width: 88%;
        max-width: 88%;
    `}
`

const CareerContentWrap = styled.div`

`

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
`

const CareerTitle = styled.h1`
    font-size: 3rem;
    font-size: 2.5rem;
    line-height: 2.5rem;
    font-weight: 800;
    margin: 1rem 0 0rem 0;
    padding-bottom: 0;
    background: ${colors.gradient2};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    ${media.tablet`
        margin: 3rem 0 0 0;
        padding-bottom: 3rem;
        font-size: 2.5rem;
        line-height: 2.5rem;
    `};
    ${media.desktop`
        font-size: 4rem;
        line-height: 4rem;
    `};

    ${media.midDesktop`
        font-size: 4.5rem;
        line-height: 5rem;
        padding-right: 5rem;
    `};
`

const SocialIconsWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;;
    width: auto;
    padding-top: 5.2rem;
    display: none;
    ${media.midDesktop`
        display: block;
    `};
`

const CareerContent = styled.div`
    margin-top: 2rem;
    ${media.tablet`
        margin-top: 0;
        margin-bottom: 2rem;
    `}
    ${media.xlDesktop`
        margin-top: 0;
    `}
`

const CareerContentBlock = styled.div`
    max-width: 1000px;
    margin-bottom: 1rem;
    ul{
        padding-left: 5px;
        max-width: 30rem;
    }
    .intro{
        padding-bottom: 1rem;
        ul{
            max-width: 100%;
        }
    }
    h2:first-child{
        margin-top: 0;
    }
    ${media.tablet`
        margin-bottom: 1rem;
    `}
`

const CareerDetailBackLink = styled.div`
    padding: 2rem 0 1rem 0;
    a{
        font-style: italic;
    }
`

const CareerDetailBackLinkInner = styled.div`
    font-weight: 500;
    color: ${colors.grey};
    svg{
        width: 20px;
        position: relative;
        top: -1px;
        display: inline;
        margin-right: 10px;
    }
    svg polyline{
        stroke: ${colors.grey};
        transition: all 0.3s ease;
    }
    a :hover svg polyline{
        stroke: orange;
        transition: all 0.3s ease;
    }
    a :hover{
        color: orange;
        transition: all 0.3s ease;
    }
`

const HeaderSection = (data) => {
        const careerData = data.data

        // Translated slug for the backlink
        let slug = 'careers'
        if (careerData.language == "nl"){
            slug = 'jobs'
        }

        return (
            <CareerContentWrap>
                <SectionExtended>
                    <MainContainer>
                        <CareerDetailBackLink>
                                <CareerDetailBackLinkInner>
                                    <AniLink
                                        to={`/${careerData.language}/${slug}`}
                                        cover direction="right" 
                                        duration={1.2}
                                        bg="orange"
                                    >
                                        <Arrow/>
                                        <FormattedMessage id="careersHeaderTitle"/>
                                    </AniLink>
                                </CareerDetailBackLinkInner>
                        </CareerDetailBackLink>
                        <RevealOpacity>
                            <CareerTitle>
                                {careerData.title}
                            </CareerTitle>
                            <SocialIconsWrapper>
                                <SocialIcons />
                            </SocialIconsWrapper>
                        </RevealOpacity>
                    </MainContainer>
                    <RevealOpacity>
                        <CareerContent className="grid grid-cols-12 gap-4">
                            <CareerContentBlock className="col-span-12">
                                <GlobalTextStyles>
                                    <div className="intro">
                                        {careerData.careerIntro && parse(careerData.careerIntro)}
                                    </div>
                                </GlobalTextStyles>
                            </CareerContentBlock>
                            <CareerContentBlock className="col-span-12 md:col-span-6">
                                <GlobalTextStyles>
                                    {careerData.careerTextBlock1 && parse(careerData.careerTextBlock1)}
                                </GlobalTextStyles>
                            </CareerContentBlock>
                            <CareerContentBlock className="col-span-12 md:col-span-6">
                                <GlobalTextStyles>
                                    {careerData.careerTextBlock2 && parse(careerData.careerTextBlock2)}
                                </GlobalTextStyles>
                            </CareerContentBlock>
                            <CareerContentBlock className="col-span-12 md:col-span-6">
                                <GlobalTextStyles>
                                    {careerData.careerTextBlock3 && parse(careerData.careerTextBlock3)}
                                </GlobalTextStyles>
                            </CareerContentBlock>
                        </CareerContent>
                    </RevealOpacity>
                </SectionExtended>
                <ContactForm jobtype={careerData.title}/>
            </CareerContentWrap>
        )
}

export default HeaderSection