import React from 'react'
import styled from 'styled-components'
import { media , colors , fonts } from '../../../lib/style-utils'
import ListArrow from '../../../images/list-arrow.svg'

const ParagraphWrap = styled.div`

    

    p, pre{
        font-weight: normal;
        font-size: 1rem;
        line-height: 1.8rem;
    }

    p:last-child{margin-bottom: 0 !important;}
    p~ol, p~ul{margin-top: 1rem !important;}

    strong{
        font-weight: bold !important;
    }

    pre{
        background-color: ${colors.light_grey};
        font-family: ${fonts.Montserrat};
        padding: 4rem 2rem 2rem 2rem;
        white-space: pre-wrap; 
        white-space: -moz-pre-wrap; 
        white-space: -pre-wrap;
        white-space: -o-pre-wrap; 
        word-wrap: break-word; 
        border-radius: 2rem 0;
        margin: 2rem 0;
        position: relative;
        strong{
            font-weight: 800 !important;
        }
    }

    pre ::after{
        content: 'TIP';
        position: absolute;
        top: 1rem;
        left: 0;
        width: auto;
        height: auto;
        background: ${colors.gradient};
        color: ${colors.white};
        font-size: 1.2rem;
        line-height: 1.2rem;
        font-weight: bold;
        padding: 0.4rem 1rem 0.4rem 2rem;
    }

    blockquote{
        font-size: 1.2rem;
        margin: 0 auto;
        padding: 0.5rem 0 0.5rem 0;
        text-align: center;
        font-style: italic;
        max-width: 40rem;
    }

    .table{
        background-color: ${colors.light_grey};
        font-family: ${fonts.Montserrat};
        padding: 2rem 2rem 2rem 2rem;
        white-space: pre-wrap; 
        white-space: -moz-pre-wrap; 
        white-space: -pre-wrap;
        white-space: -o-pre-wrap; 
        word-wrap: break-word; 
        border-radius: 2rem 0;
        margin: 2rem 0;
        position: relative;
        h3{
            color: ${colors.dark_grey};
            margin-top: 0 !important;
        }

    }

    .sources *{
        font-size: 1rem;
        color: orange;
        margin-top: 2rem;&&&&
    }

    h1,h2,h3,h4,h5 {
        margin-top: 2.5rem;
    }

    h2{
        font-size: 1.6rem !important;
        line-height: 1.8rem;
        margin: 4rem 0 1rem 0;
        font-weight: bold;
    }

    h3{
        font-size: 1.2rem !important;
    }

    ul, ol{
        font-style: normal;
        padding-left: 1rem;
        font-size: 1rem;
        line-height: 1.8rem;
        margin-bottom: 1.5rem !important;
        li {
            color: gray;
        }
    }

    ul li, ol li{
        list-style: none;
        padding-left: 20px;
        background-image: url(${ListArrow});
        background-repeat: no-repeat;
        background-position: left 9px;
        background-size: 10px;
    }

    ${media.phoneXL`

    `}

    ${media.phablet`
        
    `};

    ${media.tablet`
        blockquote{
            padding: 0.6rem 0 1.5rem 0;
        }
    `};

    ${media.desktop`
        ul, ol{
            padding-left: 2rem;
        }
    `}

    a, a:hover{color: ${colors.orange}; font-weight: 500; transition: color ease 0.4s;}
    a:hover{color: ${colors.orange}; transition: color ease 0.4s;}

`

const GlobalTextStyles = ({children}) => {

    return(
        <ParagraphWrap>
            {children}
        </ParagraphWrap>
    )
}

export default GlobalTextStyles