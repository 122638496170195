import React from 'react'
import styled from 'styled-components'
import { media } from '../../../lib/style-utils'
import { StaticQuery,graphql } from "gatsby"

import IconInstagram from './Icons/IconInstagram'
import IconFacebook from './Icons/IconFacebook'
import IconLinkindin from './Icons/IconLinkindin'

/* icon wrapper div*/
const IconDiv = styled.div`
    width: 25px;
    margin: 0 0 0.8rem 0;
    ${media.midDesktop`
        margin: auto 0 0.8rem auto;
    `};
`

const Social = () => {
    return (
        <StaticQuery 
            query={graphql`
                query FooterSocialQuery {
                    allCraftGlobalSetInterface {
                            nodes {
                                ... on craft_companySocials_GlobalSet {
                                    facebookUrl
                                    instagramUrl
                                    linkedinUrl
                                    twitterUrl
                                }
                            }
                        }
                    }       
            `}
            render={data => (
                <div className="icons_social_wrap">
                    <IconDiv className="icon_div">
                        <a href={data.allCraftGlobalSetInterface.nodes[1].facebookUrl} target="_blank" rel="noreferrer">
                            <IconFacebook />
                        </a>
                    </IconDiv>
                    <IconDiv className="icon_div">
                        <a href={data.allCraftGlobalSetInterface.nodes[1].instagramUrl} target="_blank" rel="noreferrer">
                            <IconInstagram />
                        </a>
                    </IconDiv>
                    <IconDiv className="icon_div">
                        <a href={data.allCraftGlobalSetInterface.nodes[1].linkedinUrl} target="_blank" rel="noreferrer">
                            <IconLinkindin />
                        </a>
                    </IconDiv> 
                </div>
            )}
        />
    )
}

export default Social