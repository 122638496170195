import React from 'react'

const Arrow = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.78 8.06">
            <polyline 
            fill="none"
            stroke="#343434"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            points="13.28,4.03 1.05,4.03 6.61,7.56 0.5,4.03 6.61,0.5 "/>                
        </svg>
    )
}

export default Arrow