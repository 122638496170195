import React, {Component} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl';
import axios from "axios";

import { fonts, colors, media } from '../../../../lib/style-utils'
import {encodeForm, errors, success } from './helper'
// import Tabgroup from './TabGroup'
import ErrorIcon from '../../../General/ErrorIcon/ErrorIcon'

import UploadIcon from '../../../../images/upload.svg'


const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  width: 100%;
  background-color: white;
  font-weight: bold;
  padding: 1rem 1rem;
  margin: 0 auto;
  ${media.tablet`
    width: 100%;
  `};
  ${media.desktop`
    width: 100%;
  `};
`

const Fieldset = styled.div`
    display: flex;
    justify-align: space-between;
    flex-direction: column;
    border: unset;
    width: 100%;
    margin-bottom: 1.5rem;;
`
const Fieldset2 = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;;
`

const Label = styled.label`
  color: ${colors.dark_grey};
  padding-bottom: 0.5rem;
  padding-top: 1rem;
  display: flex;
  flex-direction: row;
  font-family: ${fonts.Montserrat};
  font-weight: 600;
  font-size: 0.7rem;
`

const FileError = styled.label`
  color: #ec7d36;
  padding-left: 2rem;
  padding-top: 0.7rem;
  font-size: 0.7rem;
  display: flex;
  flex-direction: row;
`

const LabelFile = styled.label`
  font-size: 0.7em;
  height: 3rem;
  color: ${colors.customWhite};
  background-color: ${props => props.borderColor || colors.lightGrey};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  align-self: baseline;
  font-family: ${fonts.Montserrat};
  border-radius: 0.4rem;
  img {
    height: 2rem;
    width: 2rem;
    padding-right: 1rem;
  }
  &:hover {
    cursor: pointer;
    color: orange;
  }
`

const Input = styled.input`
  font-size: 0.8rem;
  font-family: ${fonts.helvetica};
  font-weight: lighter;
  height: 2.2rem;
  padding-left: 0;
  margin-bottom: 1rem;
  width: 100%;
  color: grey;
  border-bottom: 0.01rem solid ${props => props.borderColor || colors.grey};
  outline: none;
  transition-property: box-shadow;
  transition-duration: 0.4s;
  transition-timing-function: linear;
  &:focus {
    box-shadow: inset 0px -2px 0px 0px ${props => props.borderColor || colors.main};
    outline: 0;
  }
  ${media.desktop`
    width: 100%;
  `};
`
const InputName = styled(Input)`
  width: 45%;
  ${media.tablet`
    width: 45%;
    margin-bottom: 0;
  `};
  ${media.desktop`
    width: 45%;
  `};
`

const Submit = styled.button`
  width: 12rem;
  height: 2.5rem;
  text-align: center;
  border: 1.2px solid black;
  background-color: white;
  border-radius: 4rem;
  font-size: 0.8rem;
  color: ${colors.dark_grey};
  display: flex;
  margin: 1.2rem auto;
  margin-top: 3rem;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 4rem;
  font-size: 0.8rem;
  color: ${colors.dark_grey};
  box-shadow: 0px 10px 40px rgba(20, 20, 20, 0.2);
  margin-top: 1rem;
  padding: 0.4rem 2rem;
  ${media.tablet`
      border: 1.2px solid ${colors.dark_grey};
      background: ${colors.white};
      color: ${colors.dark_grey};
      transition: all 0.3s ease;
      min-width: 10rem;
      margin-left: 0;
      :hover{
          transition: all 0.3s ease;
          box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.25), 0 4px 6px -2px rgba(0, 0, 0, 0.05); 
          border-color: orange;
          color: orange;
      }
  `};
`

const File = styled.input`
  display: none;
`

const Error = styled.span`
  font-family: ${fonts.helvetica};
  font-weight: lighter;
  font-size: 0.6rem;
  text-align: left;
  padding-top: 0.2rem;
  color: #ec7d36;
`

const TextArea = styled.textarea`
  display: flex;
  font-size: 0.8rem;
  color: #70777f;
  height: 6rem;
  flex-direction: row;
  border: none;
  border-bottom: 0.03rem solid ${colors.grey};
  height: 2.2rem;
  width: 100%;
  padding: 0.7rem 0;
  outline: none;
  transition: box-shadow 0.4s linear;
  &:focus {
    box-shadow: inset 0px -3px 0px 0px ${colors.main};
    outline: 0;
  }
  ${media.tablet`
    margin-bottom: 0;
  `};
`
const FieldBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  ${media.phablet`
    flex-direction: row;
  `};
  ${media.desktop`
    width: 100%;
  `};
`
const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  ${media.phablet`
    width: 45%;
  `};
`

const Box2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`

const Icon = styled.img`
  width: 1rem;
  display: flex;
  user-select: none;
  ${media.tablet`
    width: 1rem;
  `};
  ${media.desktop`
    width: 1.3rem;
  `};
`
const Invis = styled.input`
  display: none;
  `
const honeypot = 'r45gDF!$F!'

const initialState = {
  name: '',
  lastName: '',
  email: '',
  jobName:'',
  phoneNumber: '',
  textarea: '',
  [honeypot]: '',
  emailCheck: false,
  nameCheck: false,
  fileCheck: false,
  isSelected: false,
  file: '',
  fileName: '',
  submitCheck: false,
}

class Form extends Component {
    static propTypes = {
        showFile: PropTypes.bool,
        formName: PropTypes.string,
      }
    
      static defaultProps = {
        showFile: true,
      }
    
      state = initialState
    
      handleInput = ({ target: { name, files } }) => {
        if (files.length > 0) {
          this.setState({
            [name]: files[0],
            fileName: files[0].name,
            isSelected: true,
            fileCheck: false,
          })
        } else {
          this.setState({
            fileName: '',
            isSelected: false,
          })
        }
      }
    
      handleChange = e => {
        this.setState({ [e.target.name]: e.target.value }, () => {
          const { name, lastName, email} = this.state
    
          if (name !== '' && lastName !== '') {
            this.setState({ nameCheck: false })
          }
    
          if (email !== '') {
            this.setState({ emailCheck: false })
          }
        })
      }
    
      handleSubmit = e => {
        const { fileName, name, lastName, email, jobName } = this.state
        const { jobtype } = this.props
        const newState = {
          fileCheck: !fileName,
          nameCheck: name === '' || lastName === '',
          emailCheck: email === '',
        }
        this.setState({jobName: jobtype})
    
        e.preventDefault()
        this.setState(newState, this.checkError)
      }
    
      checkError = () => {
        const { emailCheck, nameCheck, fileCheck, showFile } = this.state
    
        if (showFile) {
          if (!emailCheck && !nameCheck && !fileCheck) {
            this.fetchdata()
          }
        } else if (!emailCheck && !nameCheck) {
          this.fetchdata()
        }
      }

      fetchdata = async () => {    
        this.setState({ submitCheck: true })
        /*
        try {
          await fetch('/', {
            method: 'POST',
            body: encodeForm({ 'form-name': formName, ...this.state }),
          })
    
          success()
          this.setState({ ...initialState, submitCheck: false })
        } catch (error) {
          errors(error)
          this.setState({ submitCheck: false })
        }
        */
        // https://formspree.io/f/myybwjpb
        axios({
          method: "POST",
          url: "https://formspree.io/f/myybwjpb",
          data: encodeForm({...this.state}),
          headers: {'Content-Type': 'multipart/form-data' }})
          .then(r => {
            success()
            this.setState({ ...initialState, submitCheck: false })
          })
          .catch(r => {
            errors(r.response)
            this.setState({ submitCheck: false })
          });
      }
    render() {
        const {
             name,
             lastName,
             email,
             textarea,
             nameCheck,
             emailCheck,
             fileCheck,
             phoneNumber,
             fileName,
             isSelected,
             submitCheck,
        } = this.state
    
      const { showFile, jobtype, intl } = this.props

      let loadingMessage = intl.formatMessage({id: 'loadingMessage'});

      return(
        <FormContainer
          onSubmit={this.handleSubmit}
          data-netlify="true"
          data-netlify-honeypot={honeypot}
          enctype="multipart/form-data"
          charset="utf-8"
          action="https://formspree.io/f/myybwjpb"
        >
          <Invis name="country-field" onChange={this.handleChange} />
          {jobtype}
          <Label htmlFor="name">
          <FormattedMessage id="formName" />&nbsp;
            <ErrorIcon showError={nameCheck} />
          </Label>
          <Fieldset2>
            <InputName
              type="text"
              name="name"
              placeholder={intl.formatMessage({id: 'formSurName'})}
              value={name}
              onChange={this.handleChange}
              borderColor={nameCheck && colors.lightRed}
            />
            <InputName
              type="text"
              name="lastName"
              placeholder={intl.formatMessage({id: 'formLastName'})}
              value={lastName}
              onChange={this.handleChange}
              borderColor={nameCheck && colors.lightRed}
            />
          </Fieldset2>
          {nameCheck && <Error><FormattedMessage id="formNameError" /></Error>}
          <FieldBox>
             <InputBox>
                <Label htmlFor="email">
                <FormattedMessage id="formEmail" />&nbsp;
                  <ErrorIcon showError={emailCheck} />
                </Label>
                <Fieldset>
                  <Input
                    type="email"
                    name="email"
                    placeholder={intl.formatMessage({id: 'exampleEmail'})}
                    value={email}
                    onChange={this.handleChange}
                    borderColor={emailCheck && colors.lightRed}
                  />
                  {emailCheck && <Error><FormattedMessage id="formEmailError" /></Error>}
                </Fieldset>
             </InputBox>
             <InputBox>
                <Label htmlFor="phoneNumber"><FormattedMessage id="formNumber" /></Label>
                <Fieldset>
                  <Input
                    type="tel"
                    name="phoneNumber"
                    placeholder="+32 475 59 58 45"
                    value={phoneNumber}
                    onChange={this.handleChange}
                  />
                </Fieldset>
             </InputBox>
          </FieldBox>
          {showFile && (
            <div>
              <Label htmlFor="file">
                Cv&nbsp;
                <ErrorIcon showError={fileCheck} />
              </Label>
              <Fieldset>
                <Box2>
                  <LabelFile htmlFor="file" borderColor={fileCheck && colors.lightRed}>
                    <File
                      type="file"
                      name="file"
                      id="file"
                      onChange={this.handleInput}
                      accept="application/msword,text/plain, application/pdf,"
                    />
                    <Icon src={UploadIcon} alt="Upoadicon" />
                    {isSelected ? '1 File selected' : <FormattedMessage id="formFile" />}
                  </LabelFile>
                  <FileError>
                    {fileCheck ? <Error>*<FormattedMessage id="formFileError" /></Error> : fileName}
                  </FileError>
                </Box2>
              </Fieldset>
            </div>
          )}
          <Label htmlFor="textarea"><FormattedMessage id="formMessage" /></Label>
          <Fieldset>
            <TextArea name="textarea" value={textarea} onChange={this.handleChange} />
          </Fieldset>
          <Submit type="submit" disabled={submitCheck ? 'disabled' : ''}>
            {submitCheck ? loadingMessage : <FormattedMessage id="buttonSend" />}
          </Submit>
        </FormContainer>
    )
}}

export default Form