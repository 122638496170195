import { notify } from 'react-notify-toast'
import { FormattedMessage } from 'react-intl';

export const encodeForm = data => {
    const formData = new FormData()
    
    /* eslint-disable no-restricted-syntax */
    for (const key of Object.keys(data)) {
      formData.append(key, data[key])
    }
  
    return formData
  }

  export const success = () => {
    // Check for labguage
    let language = document.querySelector('html').getAttribute('lang');
    // Set success message based on language
    let formSuccessMessage = '';
    if (language == "nl"){
      formSuccessMessage = "We hebben je bericht goed ontvangen. We nemen zo snel mogelijk contact met je op. Geniet van je dag!";
    } else {
      formSuccessMessage = "We received your message well. We’ll get back to you as soon as possible. Enjoy your day!";
    }
    // Notification
    notify.show(formSuccessMessage, 'custom', 5000, {
      background: '#f0b353',
      text: "white",
    })
  }

export const errors = () =>
  notify.show('Fout verzonden', 'custom', 5000, {
    background: '#f0b353',
    text: "white",
  })
